.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btneditbox>section>button {
  margin:30px;
}

.loading_box {
  display: flex;
  justify-content: center;
  text-align: center;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 33333;
  background-color: #f0f8ffd6;
  align-items: center;
}
.loading_box.hidden {
  display: none;
}
.loading_box img {
  width: 105px;
  transition-duration: 1s;
  animation: scale 700ms alternate infinite ease-in;
}

.input-rounded-10 {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

@keyframes scale {
  from {
    transform: scale(1.1);
  }
  to {
    transform: scale(1.3);
  }
}

.arrow-back-form {
  position: absolute;
  right: 11%;
}

.main-header-el-right {
  position: absolute;
  right: 6%;
}

.main-header-el-left {
  position: absolute;
  left: 6%;
}


.img_bottom_carsoaul{
  position: absolute !important;
  width: 150px !important;


}
.carousel-root .dot.selected{
  background-color: #2857BD !important;
  width: 10px !important;
  height: 10px !important;
}
.carousel-root .dot{
  background-color: #666666 !important;
  width: 8px !important;
  height: 8px !important;
  box-shadow:none !important;
}
.carousel-root .control-dots{
  bottom : -9px !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {


}

/* Small devices (portrait tablets and large phones, 600px and up) */


@media only screen and (min-width: 600px) {
  .main-header-el-right {
    position: absolute;
    right: 6%;
  }

  .main-header-el-left {
    position: absolute;
    left: 6%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .main-header-el-right {
    position: absolute;
    right: 20%;
  }

  .main-header-el-left {
    position: absolute;
    left: 20%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .main-header-el-right {
    position: absolute;
    right: 23%;
  }

  .main-header-el-left {
    position: absolute;
    left: 23%;
    width: 130px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {


}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1280px) {
  .main-header-el-right {
    position: absolute;
    right: 28%;
  }

  .main-header-el-left {
    position: absolute;
    left: 28%;
    width: 130px !important;
  }
}