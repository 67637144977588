@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face{
    font-family: 'Sahel';
    src: url('./assets/fonts/Sahel.eot') format('eot');
    src: url('./assets/fonts/Sahel.woff') format('woff');
  }

@font-face{
    font-family: 'iransans';
    src: url('./assets/fonts/iransans-fa.eot') format('eot');
    src: url('./assets/fonts/iransans-fa.woff') format('woff');
}


  * {
    font-family: 'Sahel';
  }

  input[type=number],input[name=date] {
      font-family: 'iransans';
  }
  body {
    background-color: rgb(166, 171, 201);
  }

.form-group>label{
    top:-15px;
    right:25px;
}
.swal-modal .swal-text {
    text-align: center;
}

